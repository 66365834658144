<template>
    <div class="content-main">
        <div class="content-header">
            <h1>每日一卡总览</h1>
        </div>
        <div>
            <el-divider></el-divider>
        </div>
        <div class="content-nav">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>每日一卡总览</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--列表布局-->
        <div class="day-card-main" v-for="item in products" :key="item.id + 'product'">
            <div style="background-color: white;padding-bottom: 10px">
                <!--标题-->
                <div class="m-title day-card-title">
                    {{item.name}}
                </div>
            </div>
            <div style="width: 60%;margin: 0 auto">
                <!--列表-->
                <div class="day-card-list">
                    <div class="day-card-list-item" v-for="card in item.cards" :key="card.id+'card'">
                        <!--img-->
                        <div>
                            <el-image
                                    fit="fill"
                                    style=""
                                    :src="card.img"
                            ></el-image>
                        </div>
                        <!--date-->
                        <span class="day-card-list-item-title">{{$moment(card.created_at).format("YYYY年MM月DD日") }}</span>
                    </div>
                </div>
                <div class="m-clear-both"></div>
            </div>
        </div>
        <div class="m-clear-both"></div>
        <!---->
        <div class="back-day-card m-a-none">
            <router-link to="/index/dayCardDetail">
                <i class="el-icon-caret-right"></i>每日一卡
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "list",
        data() {
            return {
                products: []
            }
        },
        methods: {
            getProductList(){
                this.$axios.post("site/siteProducts/getIndexList")
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.products = res.data.data;
                            console.log(this.products)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            },
        },
        created() {
            this.getProductList()
        }
    }
</script>

<style scoped>
    .content-main{
        text-align: center;
        background-color: #ffffff;
    }
    .content-nav{
        width: 60%;
        margin: 0 auto;
        padding-bottom: 30px;
    }
    .day-card-main{
        width: 100%;
        margin: 0 auto;
        background-color: #f0f0f0;
        height: auto;
    }
    .day-card-title{
        width: 40%;
        margin: 30px auto;
    }
    .day-card-list{
        width: 100%;
    }
    .day-card-list-item{
        width: 22%;
        /*float: left;*/
        display: inline-block;
        padding: 0.7%;
        margin: 0.7%;
        background-color: white;
    }
    .day-card-list-item-title{
        font-size: 18px;
        height: 30px;
        line-height: 30px;
        margin: 5px auto;
        border: solid 1px #111111;
        display: inline-block;
        color: #636b6f;
        padding: 0 5px;

    }
    .back-day-card{
        height: 100px;
    }
    .back-day-card > a{
        display: block;
        height: 66px;
        border: 1px solid black;
        width: 274px;
        margin: 40px auto 20px auto;
        line-height: 66px;
        font-size: 30px;
        font-weight: bold;
    }
</style>
